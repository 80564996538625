import React, { useState } from 'react';
import { Typography, ListItemButton, List, Menu, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const UserMenu = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  // Handles opening the menu
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handles closing the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handles user sign-out
  const handleSignOut = () => {
    sessionStorage.clear();
    handleMenuClose();
    navigate('/login');
  };

  // Determines if the menu is open
  const isMenuOpen = Boolean(anchorEl);

  return (
    <>
      <List component="nav" aria-label="User Menu">
        <ListItemButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
        >
          <AccountCircle sx={{ marginRight: 1 }} />
          <Typography variant="subtitle" component="span" sx={{ marginRight: 2 }}>
            {user.first_name} {user.last_name}
          </Typography>
        </ListItemButton>
      </List>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={handleMenuClose}>Support</MenuItem>
        <MenuItem onClick={handleMenuClose}>Help</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
