import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CardActions
} from "@mui/material";

const AvailableProductCard = ({ product, handleOpenDrawer }) => (
  <Card
    sx={{
      height: "100%",
      cursor: "pointer",
      border: "1px solid #d5e6ee",
      "&:hover": { backgroundColor: "#e0f7fa" },
    }}
    onClick={() => handleOpenDrawer(product)}>
    <CardContent>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Typography variant="h5">{product.name}</Typography>
        {product.logo && (
          <img
            src={process.env.PUBLIC_URL + product.logo}
            alt={`${product.name} logo`}
            style={{ width: "40px", height: "40px" }}
          />
        )}
      </Box>
      <Typography>{product.overview}</Typography>
      <Typography variant="body2">Price: {product.pricing}</Typography>
    </CardContent>
    <CardActions>
      <Button size="small" color="primary">
        Activate
      </Button>
    </CardActions>
  </Card>
);

export default AvailableProductCard;
