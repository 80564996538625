// Dashboard.jsx
import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import TamCases from "./TamCases";
import CsmCases from "./CsmCases";

const CaseDashboard = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ pl: 3, pr: 3 }}>
      <Typography variant="h4">Customer Success Dashboard</Typography>
      <Tabs value={tab} onChange={handleChange} sx={{ pb: 1 }}>
        <Tab label='TAM Cases' />
        <Tab label='CSM Cases' />
      </Tabs>
      {tab === 0 && <TamCases />}
      {tab === 1 && <CsmCases />}
    </Box>
  );
};

export default CaseDashboard;
