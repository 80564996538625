import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { useTheme, styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { authedApiFetch } from "../../utils/Api";
import { useParams } from "react-router-dom";

const StyledDropzone = styled(Box)(({ theme, isdragactive }) => ({
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "40px",
  textAlign: "center",
  cursor: "pointer",
  transition: "background-color 0.3s, border-color 0.3s",
  color: theme.palette.text.secondary,
  ...(isdragactive === "true" && {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }),
}));

const AscentUpload = () => {
  const [uploadStatus, setUploadStatus] = useState("");
  const theme = useTheme();
  const { tenantId } = useParams();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      uploadFile(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const uploadFile = async (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const fileContents = e.target.result;
      const base64File = btoa(
        new Uint8Array(fileContents).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      try {
        const response = await authedApiFetch({
          endpoint: `/tenants/${tenantId}/ascent_files`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          payload: {
            file_name: file.name,
            file_contents: base64File,
          },
          returnResponse: true
        });

        if (response.ok) {
          const message = await response.text();
          setUploadStatus(message);
        } else {
          const errorData = await response.text();
          setUploadStatus(`Upload failed: ${errorData}`);
        }
      } catch (error) {
        setUploadStatus(`Upload error: ${error.message}`);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <StyledDropzone
        {...getRootProps()}
        isdragactive={isDragActive.toString()}
      >
        <input {...getInputProps()} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <UploadFileOutlinedIcon fontSize="large" />
          {isDragActive ? (
            <Typography variant="h6">
              Drop files here...
            </Typography>
          ) : (
            <Typography variant="h6">
              Drag files here or click to select
            </Typography>
          )}
        </Box>
      </StyledDropzone>
      {uploadStatus && (
        <Typography variant="body2" color="textSecondary">
          {uploadStatus}
        </Typography>
      )}
    </div>
  );
};

export default AscentUpload;
