import React, { useState } from 'react';
import { Box, Tab, Tabs, Paper, Typography, Chip } from '@mui/material';
import Settings from './Settings';
import Enhancements from './Enhancements';
import { redirect, useOutletContext, useParams } from 'react-router-dom';
import { authedApiFetch } from '../../utils/Api';

export async function testAction({ params }) {
  const resp = await authedApiFetch({
    endpoint: `/connections/${params.connectionId}/test`,
    method: 'POST'
  })

  return { resp };
}

export async function syncAction({ params }) {
  const resp = await authedApiFetch({
    endpoint: `/connections/${params.connectionId}/sync`,
    method: 'POST'
  })

  return { resp };
}

export async function updateAction({ params, request }) {
  const formData = await request.formData();

  // convert formData to object but convert array values to actual array
  const newSettings = {};
  const formDataObj = Object.fromEntries(formData); // removes duplicate keys
  Object.entries(formDataObj).forEach(([key, value]) => {
    if (key.endsWith('[]')) {
      const newKey = key.substring(0, key.length - 2);
      newSettings[newKey] = formData.getAll(key); // all vals for key as array
    } else {
      newSettings[key] = value
    }
  });
  
  const connection = await authedApiFetch({
    endpoint: `/connections/${params.connectionId}`,
    method: 'PATCH',
    payload: {
      connection: {
        settings: newSettings
      }
    }
  });

  return redirect("..");
}

const ConnectionDetails = () => {
  // get connections and services from parent context,
  // then find by params to get current, prevents re-fetch
  const { connections, services, user } = useOutletContext();
  const { connectionId } = useParams()
  const connection = connections.find((connection) => connection.id == connectionId)
  const service = services.find((service) => service.id == connection.service_id)

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabsToDisplay = (() => {
    return ['settings', 'enhancements']
  })();

  const tabs = {
    settings: {
      label: 'Settings',
      content: <Settings service={service} connection={connection} user={user}/>
    },
    enhancements: {
      label: 'Enhancements',
      content: <Enhancements
        connection={connection}
        availableEnhancements={service.supported_enhancements}
        user={user}
      />
    }
  }

  const tabPanelSx = {
    padding: 3,
    boxShadow: 1,
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" sx={{ p: 1 }}>
            Connection Details: {connection.name} ({service.name})
          </Typography>
          <Chip
            color={connection.status == 'creating' ? 'warning' : 'success'}
            label={connection.status == 'creating' ? 'Creating' : 'Created'}
            />
        </Box>
        <Tabs value={value} onChange={handleChange} aria-label="connection tabs">
          { tabsToDisplay.map((tabName) => <Tab label={tabs[tabName].label} />)}
        </Tabs>
      </Box>
      {tabsToDisplay.map((tabName, index) => {
        return (
          <Paper elevation={3} sx={value === index ? tabPanelSx : { display: 'none' }}>
            {tabs[tabName].content}
          </Paper>
        )
      })}
    </>
  );
};

export default ConnectionDetails;