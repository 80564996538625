import { Navigate, useOutletContext } from "react-router-dom";

const DataTableIndex = () => {
  const { user } = useOutletContext();

  if (user.role === 'client') {
    return <Navigate to={`records`}/>;
  } else {
    return <Navigate to={`settings`}/>;
  }
}

export default DataTableIndex;