import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import Grid from '@mui/material/Grid2';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

const ImplementationDashboard = () => {
  return (
    <Box sx={{ pl: 3, pr: 3}}>
      <Typography variant="h4">Implementation Dashboard</Typography>
      <Grid container spacing={3} sx={{mt: 1}}>
        <Grid
          size={{
            xs: 12,
            sm: 4
          }}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AssignmentTurnedInOutlinedIcon fontSize="large" />
                <Box sx={{ marginLeft: 2 }}>
                  <Typography variant="h6">Total Hours</Typography>
                  <Typography variant="h4">400</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4
          }}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ScheduleOutlinedIcon fontSize="large" />
                <Box sx={{ marginLeft: 2 }}>
                  <Typography variant="h6">Hours Used</Typography>
                  <Typography variant="h4">280</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4
          }}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccessTimeOutlinedIcon fontSize="large" />
                <Box sx={{ marginLeft: 2 }}>
                  <Typography variant="h6">Hours Remaining</Typography>
                  <Typography variant="h4">120</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ImplementationDashboard;