// utils/stringUtils.js
export const toTitleCase = (str) => {
    if (typeof str !== 'string') {
      return ''; // or return str if you want to keep the original value
    }
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  

/**
 * Formats a phone number string.
 * @param {string} phoneNumber - The phone number to format, e.g., "5129391920".
 * @returns {string} The formatted phone number, e.g., "(512) 939-1920".
 */
export const formatPhoneNumber = (phoneNumber) => {
  // Ensure input is a string and has exactly 10 digits
  if (typeof phoneNumber !== 'string' || phoneNumber.length !== 10) {
    return phoneNumber; // return the input if it's not in the expected format
  }

  // Extract the area code, the first three digits, and the last four digits
  const areaCode = phoneNumber.slice(0, 3);
  const centralOfficeCode = phoneNumber.slice(3, 6);
  const lineNumber = phoneNumber.slice(6);

  // Format the number in the desired format
  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
};
