import React from 'react';

const MeetingsWidget = () => {
  return (
    <iframe
      src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3whBx-8sWSn4BQ43XPQD0qp6FhD4EmZulGoPXj68LsFL4WMdaucjIbESGpm-flUComvhgK5Rnx?gv=true"
      style={{ border: 0, width: '100%', height: '700px', frameBorder: '0' }}
      title="Google Calendar Appointment Scheduling"
    ></iframe>
  );
};

export default MeetingsWidget;
