import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, Box, Checkbox } from "@mui/material";
import { useOutletContext } from "react-router-dom";

const UserList = () => {
  const { users } = useOutletContext();

  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: "first_name", headerName: "First name", width: 130 },
    { field: "last_name", headerName: "Last name", width: 130 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phone", headerName: "Phone", width: 150 },
    {
      field: "aim",
      headerName: "AIM",
      width: 90,
      renderCell: (params) => <Checkbox checked={params.value} disabled />,
    },
    {
      field: "share_secure",
      headerName: "ShareSecure",
      width: 130,
      renderCell: (params) => <Checkbox checked={params.value} disabled />,
    },
    {
      field: "answers",
      headerName: "Answers",
      width: 100,
      renderCell: (params) => <Checkbox checked={params.value} disabled />,
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh", pl: 3, pr: 3 }}>
      <Typography variant='h4' gutterBottom>
        Users
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <DataGrid rows={users} columns={columns} pageSize={10} autoHeight={false} />
      </Box>
    </Box>
  );
};

export default UserList;
