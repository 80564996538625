import { Outlet, redirect, useLoaderData } from "react-router-dom"
import { Box } from "@mui/material";
import BottomMenu from "./BottomMenu";
import { authedApiFetch, needToLogIn } from "../utils/Api";

export const rootLoader = async () => {
  if (needToLogIn()) {
    return redirect("/login");
  }

  const tenants = await authedApiFetch({ endpoint: "/tenants", method: 'GET'});
  const user = JSON.parse(sessionStorage.getItem('user'));

  return { tenants, user };
}


const Root = () => {
  const data = useLoaderData();

  return (
    <Box sx={{ display: "flex", flex: 1, overflow: "hidden", pb: "74px" }}>
      <Outlet context={data}/>
      <BottomMenu user={data.user} tenants={data.tenants} />
    </Box>
  )
};

export default Root;