import React, { useState, useRef } from "react";
import { 
  DataGrid, 
  GridActionsCellItem, 
} from "@mui/x-data-grid";
import { 
  Box, 
  Button, 
  Typography, 
  Tabs, 
  Tab, 
  Snackbar,
  Alert,
  CircularProgress
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import EnhanceIcon from "@mui/icons-material/AutoFixHigh";
import CleanIcon from "@mui/icons-material/CleaningServices";
import SyncIcon from "@mui/icons-material/Sync";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssignmentIcon from '@mui/icons-material/Assignment';
import FileViewer from "./FileViewer";
import AscentUpload from "./AscentUpload";
import MappingReview from "./MappingReview";

const FileStatus = {
  UPLOADED: 'Uploaded',
  PROCESSING: 'Processing',
  MAPPING_READY: 'Ready for Mapping',
  MAPPING_IN_PROGRESS: 'Mapping in Progress',
  MAPPING_COMPLETE: 'Mapping Complete',
  PROCESSING_COMPLETE: 'Processing Complete',
  ERROR: 'Error'
};

const Ascent = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [notification, setNotification] = useState(null);
  const [processingFile, setProcessingFile] = useState(null);
  const [mappingReview, setMappingReview] = useState(null);

  const fileInputRef = useRef(null);
  const [rows, setRows] = useState([
    {
      id: 1,
      fileName: "emailAccounts_enhanced.csv",
      source: "Gmail",
      object: "Account",
      dateCreated: "2024-10-01",
      owner: "William Marshall",
      status: FileStatus.MAPPING_READY,
      mappingConfidence: 0.85,
    },
    {
      id: 2,
      fileName: "emailContacts.csv",
      source: "Gmail",
      object: "Contact",
      dateCreated: "2024-10-03",
      owner: "Sydney Applebaum",
      status: FileStatus.PROCESSING_COMPLETE,
      mappingConfidence: 0.92,
    },
    {
      id: 3,
      fileName: "exportedCompanies.csv",
      source: "User Uploaded",
      object: "Account",
      dateCreated: "2024-10-02",
      owner: "Billy Senior",
      status: FileStatus.MAPPING_READY,
      mappingConfidence: 0.78,
    },
  ]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const showNotification = (message, severity = 'info') => {
    setNotification({ message, severity });
  };

  const handleViewFile = (file) => {
    setSelectedFile({ ...file, type: 'view' });
  };

  const handleReviewMapping = async (file) => {
    setProcessingFile(file);
    try {
      // Simulate API call to get mapping suggestions
      const mockMappings = {
        company_name: { sourceHeader: "Company", confidence: 0.95 },
        address: { sourceHeader: "Address Line 1", confidence: 0.88 },
        employees_count: { sourceHeader: "Employee Count", confidence: 0.92 },
        year_founded: { sourceHeader: "Founded Year", confidence: 0.85 },
        industry_sector: { sourceHeader: "Industry", confidence: 0.89 },
        website: { sourceHeader: "Web URL", confidence: 0.94 }
      };

      const mockPreviewData = [
        { company_name: "Acme Corp", address: "123 Main St", employees_count: "500" },
        { company_name: "Tech Inc", address: "456 Park Ave", employees_count: "1000" },
        { company_name: "Global Ltd", address: "789 Broadway", employees_count: "250" },
      ];

      setMappingReview({
        fileName: file.fileName,
        mappings: mockMappings,
        previewData: mockPreviewData
      });

      setSelectedFile({ ...file, type: 'mapping' });
      showNotification('Mapping suggestions loaded successfully', 'success');
    } catch (error) {
      showNotification('Error loading mapping suggestions', 'error');
      console.error('Error:', error);
    }
    setProcessingFile(null);
  };

  const handleBackToList = () => {
    setSelectedFile(null);
    setMappingReview(null);
  };

  const handleSaveMapping = async (mappings) => {
    try {
      // TODO: implement save mapping logic here
      showNotification('Mapping template saved successfully', 'success');
    } catch (error) {
      showNotification('Error saving mapping template', 'error');
      console.error('Error:', error);
    }
  };

  const handleApproveMapping = async (mappings) => {
    try {
      // TODO: implement approve and process logic here
      const updatedRows = rows.map(row => 
        row.id === selectedFile.id 
          ? { ...row, status: FileStatus.PROCESSING_COMPLETE }
          : row
      );
      setRows(updatedRows);
      showNotification('File processing started', 'success');
      handleBackToList();
    } catch (error) {
      showNotification('Error processing file', 'error');
      console.error('Error:', error);
    }
  };

  const handleFileUpload = async (file) => {
    const newRow = {
      id: rows.length + 1,
      fileName: file.name,
      source: "User Uploaded",
      object: "Detecting...",
      dateCreated: new Date().toISOString().split("T")[0],
      owner: "Current User",
      status: FileStatus.PROCESSING,
      mappingConfidence: null
    };

    setRows([...rows, newRow]);
    setProcessingFile(newRow);

    try {
      // Simulate file processing
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      const updatedRow = {
        ...newRow,
        object: "Account", // This would come from detection logic
        status: FileStatus.MAPPING_READY,
        mappingConfidence: 0.82
      };

      setRows(rows => rows.map(row => 
        row.id === newRow.id ? updatedRow : row
      ));
      showNotification('File uploaded and ready for mapping', 'success');
    } catch (error) {
      setRows(rows => rows.map(row => 
        row.id === newRow.id ? { ...row, status: FileStatus.ERROR } : row
      ));
      showNotification('Error processing file', 'error');
      console.error('Error:', error);
    }
    
    setProcessingFile(null);
  };

  const columns = [
    { 
      field: "fileName", 
      headerName: "File Name", 
      minWidth: 250,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {processingFile?.id === params.row.id && (
            <CircularProgress size={16} sx={{ mr: 1 }} />
          )}
          {params.value}
        </Box>
      )
    },
    { field: "source", headerName: "Source", minWidth: 150 },
    { field: "object", headerName: "Object", minWidth: 150 },
    { field: "dateCreated", headerName: "Date Created", minWidth: 150 },
    { field: "owner", headerName: "Owner", minWidth: 150 },
    { 
      field: "status", 
      headerName: "Status", 
      minWidth: 250,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {params.row.mappingConfidence && (
            <Typography variant="caption" sx={{ mr: 1 }}>
              ({Math.round(params.row.mappingConfidence * 100)}% match)
            </Typography>
          )}
          {params.value}
        </Box>
      )
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem 
          icon={<VisibilityIcon />} 
          onClick={() => handleViewFile(params.row)} 
          label="View" 
        />,
        <GridActionsCellItem 
          icon={<AssignmentIcon />} 
          onClick={() => handleReviewMapping(params.row)} 
          label="Review Mapping"
          showInMenu
          disabled={params.row.status !== FileStatus.MAPPING_READY}
        />,
        <GridActionsCellItem 
          icon={<EnhanceIcon />} 
          onClick={() => console.log('Enhance:', params.row)} 
          label="Enhance" 
          showInMenu 
        />,
        <GridActionsCellItem 
          icon={<CleanIcon />} 
          onClick={() => console.log('Clean:', params.row)} 
          label="Clean" 
          showInMenu 
        />,
        <GridActionsCellItem 
          icon={<SyncIcon />} 
          onClick={() => console.log('Sync:', params.row)} 
          label="Sync" 
          showInMenu
          disabled={params.row.status !== FileStatus.PROCESSING_COMPLETE}
        />,
      ],
    },
  ];

  if (selectedFile) {
    if (selectedFile.type === 'mapping' && mappingReview) {
      return (
        <Box sx={{ pl: 3, pr: 3 }}>
          <Button 
            startIcon={<ArrowBackIcon />} 
            onClick={handleBackToList} 
            sx={{ mb: 2 }}
          >
            Back to File List
          </Button>
          <MappingReview
            fileName={selectedFile.fileName}
            mappings={mappingReview.mappings}
            previewData={mappingReview.previewData}
            onSaveMapping={handleSaveMapping}
            onApproveMapping={handleApproveMapping}
            onUpdateMapping={(targetField, sourceHeader) => {
              setMappingReview(prev => ({
                ...prev,
                mappings: {
                  ...prev.mappings,
                  [targetField]: { ...prev.mappings[targetField], sourceHeader }
                }
              }));
            }}
          />
        </Box>
      );
    }
    return (
      <Box sx={{ pl: 3, pr: 3 }}>
        <Button 
          startIcon={<ArrowBackIcon />} 
          onClick={handleBackToList} 
          sx={{ mb: 2 }}
        >
          Back to File List
        </Button>
        <FileViewer 
          fileName={selectedFile.fileName} 
          onSave={handleSaveMapping} 
          onSaveAndApprove={handleApproveMapping} 
        />
      </Box>
    );
  }

  return (
    <Box sx={{ pl: 3, pr: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Ascent
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Ascent is the fastest way to bring data into AltviaOne. Ascent can connect to Google Mail or Microsoft Exchange, and pull contacts,
        organizations, and interactions out of your email and prep them for sync into AIM.
      </Typography>
      
      <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mt: 3, mb: 3 }}>
        <Tab label="Email Data Sync" />
        <Tab label="Uploaded Data" />
      </Tabs>

      {tabIndex === 0 && (
        <>
          <Button
            variant="contained"
            disabled
            startIcon={<GoogleIcon />}
            sx={{
              mr: 3,
              "&.Mui-disabled": {
                backgroundColor: "#1a73e8",
                color: "#fff",
                border: "1px solid #1a73e8",
              },
            }}>
            Gmail Connected
          </Button>
          <Button 
            variant="outlined" 
            startIcon={<SettingsSuggestOutlinedIcon />} 
            sx={{ mr: 3 }}
          >
            Configure Sync
          </Button>
          <DataGrid
            rows={rows.filter(row => row.source === "Gmail")}
            columns={columns}
            pageSize={5}
            sx={{ mt: 3 }}
          />
        </>
      )}

      {tabIndex === 1 && (
        <>
          <input type='file' ref={fileInputRef} style={{ display: "none" }} onChange={handleFileUpload} />
          {/* <Button variant='outlined' startIcon={<UploadFileOutlinedIcon />} onClick={handleUploadClick}>
            Upload Data
          </Button> */}
          <AscentUpload />
          <DataGrid 
            rows={rows.filter(row => row.source === "User Uploaded")}
            columns={columns}
            pageSize={5}
            sx={{ mt: 3 }}
            components={{
              MoreActionsIcon: MoreVertIcon,
            }}
          />
        </>
      )}

      <Snackbar 
        open={!!notification} 
        autoHideDuration={6000} 
        onClose={() => setNotification(null)}
      >
        <Alert 
          onClose={() => setNotification(null)} 
          severity={notification?.severity || 'info'} 
          sx={{ width: '100%' }}
        >
          {notification?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Ascent;