import React, { useEffect } from 'react';
import {
  Typography,
  Box
} from "@mui/material";
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { authedApiFetch } from '../../utils/Api';
import { useTheme } from "@mui/material/styles";

const QSEmbeddedAuthor = () => {
  const theme = useTheme();

  useEffect(() => {
    const embedConsole = async () => {
      const embeddingContext = await createEmbeddingContext();

      const containerDiv = document.getElementById("embedding-container");

      const embedUrlResponse = await authedApiFetch({ endpoint: "/generate_embed_url", method: 'GET' });
      const embedUrl = embedUrlResponse.embed_url
      console.log(embedUrl)

      const frameOptions = {
        url: embedUrl, // replace with your embed URL
        container: containerDiv,
        height: "85%",
        width: "85%",
      };

      const contentOptions = {

      };

      await embeddingContext.embedConsole(frameOptions, contentOptions);
    };

    embedConsole();
  }, []); // Run on component mount

  return (
    <Box sx={{ pl: 3, pr: 3 }}>
      <Typography variant="h4" gutterBottom>
        Answers
      </Typography>
      <div id="embedding-container"
        style={{
          height: '90vh', 
          width: '90vw',
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.shadows[1],
        }}></div>
    </Box>
  );
};

export default QSEmbeddedAuthor;
