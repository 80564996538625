import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Modal,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";

import SavingsIcon from "@mui/icons-material/Savings";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BusinessIcon from "@mui/icons-material/Business";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupIcon from "@mui/icons-material/Group";
import SendIcon from "@mui/icons-material/Send";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BarChartIcon from "@mui/icons-material/BarChart";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WebIcon from "@mui/icons-material/Web";
import InsightsIcon from "@mui/icons-material/Insights";
import BugReportIcon from "@mui/icons-material/BugReport";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalculateIcon from "@mui/icons-material/Calculate";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { useTheme } from "@mui/material/styles";

const iconMap = {
  Accounts: BusinessIcon,
  Contacts: ContactPageIcon,
  Interactions: ContactMailIcon,
  Fundraising: AttachMoneyIcon,
  Entity: SavingsIcon,
  Pipeline: AccountTreeIcon,
  Investors: ApartmentIcon,
  "Investor Contacts": ContactPageIcon,
  Groups: GroupIcon,
  Batches: AllInboxIcon,
  Delivery: SendIcon,
  Deal: DirectionsRunIcon,
  Investment: ScheduleIcon,
  "Data Room": WebIcon,
  "Investor Portal": WebIcon,
  "Deal Room": WebIcon,
  "Portco Data Room": WebIcon,
  Documents: ContentCopyIcon,
  Reports: BarChartIcon,
  Insights: InsightsIcon,
  DataQuality: BugReportIcon,
  "User Activity": TrackChangesIcon,
  Fund: AccountBalanceIcon,
  "Fund Holding": BusinessIcon,
  "Fund Holding Transaction": AttachMoneyIcon,
  Financials: CalculateIcon,
  Checklists: AssignmentIcon,
  "Investment Vehicle": BusinessIcon,
  Investor: ApartmentIcon,
  "Investor Contact": ContactPageIcon,
  Transaction: AttachMoneyIcon,
  Metric: BarChartIcon,
  PortCo: BusinessIcon,
  KPIs: InsightsIcon,
  Valuation: CalculateIcon,
  Transfers: DriveFileMoveIcon,
  Compliance: GavelOutlinedIcon,
  Calculations: CalculateOutlinedIcon,
  Monitoring: VisibilityOutlinedIcon,
};

const IconWithLabel = ({ name, type, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    handleClose();
    onClick(name, action);
  };

  const getMenuItems = () => {
    const items = [];

    if (type === "object" || type === "document") {
      items.push(
        <MenuItem key='onboard' onClick={() => handleMenuItemClick("onboard")}>
          <CloudUploadIcon sx={{ mr: 1 }} /> Onboard Data
        </MenuItem>
      );
    }

    items.push(
      <MenuItem key='documentation' onClick={() => handleMenuItemClick("documentation")}>
        <DescriptionIcon sx={{ mr: 1 }} /> View Documentation
      </MenuItem>,
      <MenuItem key='walkthroughs' onClick={() => handleMenuItemClick("walkthroughs")}>
        <OndemandVideoIcon sx={{ mr: 1 }} /> Walk-throughs & Use Cases
      </MenuItem>
    );

    return items;
  };

  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' height='100%' sx={{ cursor: "pointer" }}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        bgcolor='white'
        borderRadius='50%'
        width={36}
        height={36}
        mb={1}
        border='1px solid #4A86E8'
        onClick={handleClick}>
        {iconMap[name] ? React.createElement(iconMap[name], { style: { fontSize: 24, color: "#2E86AB" } }) : null}
      </Box>
      {!["Reports", "Insights", "DataQuality", "Documents"].includes(name) && (
        <Typography variant='caption' align='center' sx={{ pl: 1, pr: 1 }}>
          {name}
        </Typography>
      )}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {getMenuItems()}
      </Menu>
    </Box>
  );
};

const UDM = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const theme = useTheme();

  const handleOpen = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleClose = () => setModalOpen(false);

  const handleIconClick = (name, action) => {
    let content = "";
    switch (action) {
      case "onboard":
        content = `Onboard data for ${name}`;
        break;
      case "documentation":
        content = `View documentation for ${name}`;
        break;
      case "walkthroughs":
        content = `Walk-throughs and use cases for ${name}`;
        break;
      default:
        content = `Action for ${name}`;
    }
    handleOpen(content);
  };

  const data = [
    {
      name: "Relationship Management",
      aim: { objects: ["Accounts", "Contacts", "Interactions"], processes: [] },
      shareSecure: { workspaces: [], documents: ["Documents"] },
      answers: { reports: ["Reports"], insights: ["Insights"] },
      dataPeak: ["DataQuality"],
    },
    // {
    //   name: "Interaction Tracking",
    //   aim: { objects: ["Interactions"], processes: [] },
    //   shareSecure: { workspaces: [], documents: [] },
    //   answers: { reports: ["Reports"], insights: ["Insights"] },
    //   dataPeak: ["DataQuality"],
    // },
    {
      name: "Fundraising",
      aim: { objects: ["Fundraising", "Entity", "User Activity"], processes: ["Pipeline"] },
      shareSecure: { workspaces: ["Data Room"], documents: ["Documents"] },
      answers: { reports: ["Reports"], insights: ["Insights"] },
      dataPeak: ["DataQuality"],
    },
    {
      name: "Investor Relations",
      aim: { objects: ["Investors", "Investor Contacts", "Groups", "User Activity"], processes: [] },
      shareSecure: { workspaces: ["Investor Portal"], documents: ["Documents"] },
      answers: { reports: ["Reports"], insights: ["Insights"] },
      dataPeak: ["DataQuality"],
    },
    {
      name: "Investor Correspondence",
      aim: { objects: ["Batches"], processes: ["Delivery"] },
      shareSecure: { workspaces: [], documents: ["Documents"] },
      answers: { reports: ["Reports"], insights: ["Insights"] },
      dataPeak: ["DataQuality"],
    },
    {
      name: "Deal Management",
      aim: { objects: ["Deal", "Investment", "User Activity"], processes: ["Pipeline"] },
      shareSecure: { workspaces: ["Deal Room"], documents: ["Documents"] },
      answers: { reports: ["Reports"], insights: ["Insights"] },
      dataPeak: ["DataQuality"],
    },
    {
      name: "Fund Management",
      aim: { objects: ["Fund", "Fund Holding", "Transaction", "Financials"], processes: ["Transfers"] },
      shareSecure: { workspaces: [], documents: [] },
      answers: { reports: ["Reports"], insights: ["Insights"] },
      dataPeak: ["DataQuality"],
    },
    {
      name: "Compliance",
      aim: { objects: ["Checklists"], processes: ["Compliance"] },
      shareSecure: { workspaces: [], documents: ["Documents"] },
      answers: { reports: ["Reports"], insights: ["Insights"] },
      dataPeak: ["DataQuality"],
    },
    {
      name: "Fund Accounting",
      aim: { objects: ["Entity", "Investor", "Investor Contact", "Transaction", "Metric"], processes: ["Calculations"] },
      shareSecure: { workspaces: ["Investor Portal"], documents: ["Documents"] },
      answers: { reports: ["Reports"], insights: ["Insights"] },
      dataPeak: ["DataQuality"],
    },
    {
      name: "Portfolio Management",
      aim: { objects: ["PortCo", "KPIs", "Valuation"], processes: ["Monitoring"] },
      shareSecure: { workspaces: ["Portco Data Room"], documents: ["Documents"] },
      answers: { reports: ["Reports"], insights: ["Insights"] },
      dataPeak: ["DataQuality"],
    },
  ];

  return (
    <>
      <Typography variant='h4' sx={{ m: 2 }}>
        Universal Data Model
      </Typography>
      <Typography variant='body1' sx={{ m: 2 }}>
        The Unified Data Model (UDM) is a comprehensive data model that provides a common language for the data in your organization. It is designed
        to be used across all of your applications, enabling you to easily share data between them.
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='UDM table'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell colSpan={3} align='center' sx={{ backgroundColor: "#D9EAD3" }}>
                <Typography variant='subtitle' sx={{ fontWeight: "bold", fontVariant: "uppercase" }}>
                  AIM
                </Typography>
              </TableCell>
              <TableCell colSpan={2} align='center' sx={{ backgroundColor: "#D0E0E3" }}>
                <Typography variant='subtitle' sx={{ fontWeight: "bold" }}>
                  SHARESECURE
                </Typography>
              </TableCell>
              <TableCell colSpan={2} align='center' sx={{ backgroundColor: "#C9DAF8" }}>
                <Typography variant='subtitle' sx={{ fontWeight: "bold" }}>
                  ANSWERS
                </Typography>
              </TableCell>
              <TableCell align='center' sx={{ backgroundColor: "#CFE2F3" }}>
                <Typography variant='subtitle' sx={{ fontWeight: "bold" }}>
                  DATAPEAK
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell colSpan={2} align='center' sx={{ backgroundColor: "#D9EAD3" }}>
                Objects
              </TableCell>
              <TableCell align='center' sx={{ backgroundColor: "#D9EAD3" }}>
                Processes
              </TableCell>
              <TableCell align='center' sx={{ backgroundColor: "#D0E0E3" }}>
                Workspaces
              </TableCell>
              <TableCell align='center' sx={{ backgroundColor: "#D0E0E3" }}>
                Documents
              </TableCell>
              <TableCell align='center' sx={{ backgroundColor: "#C9DAF8" }}>
                Reports
              </TableCell>
              <TableCell align='center' sx={{ backgroundColor: "#C9DAF8" }}>
                Insights
              </TableCell>
              <TableCell align='center' sx={{ backgroundColor: "#CFE2F3" }}>
                Quality
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.name} sx={{ position: "relative", height: "80px" }}>
                <TableCell
                  component='th'
                  scope='row'
                  align='right'
                  onClick={() => handleOpen(`Content for ${row.name}`)}
                  sx={{ fontWeight: "bold", cursor: "pointer", "&:hover": { backgroundColor: theme.palette.secondary.main } }}>
                  {row.name}
                </TableCell>
                <TableCell colSpan={2} align='center' sx={{ backgroundColor: "#D9EAD3" }}>
                  <Box display='flex' justifyContent=''>
                    {row.aim.objects.map((item, index) => (
                      <IconWithLabel key={index} name={item} type='object' onClick={handleIconClick} />
                    ))}
                  </Box>
                </TableCell>
                <TableCell align='center' sx={{ backgroundColor: "#D9EAD3" }}>
                  {row.aim.processes.map((item, index) => (
                    <IconWithLabel key={index} name={item} type='process' onClick={handleIconClick} />
                  ))}
                </TableCell>
                <TableCell align='center' sx={{ backgroundColor: "#D0E0E3" }}>
                  <Box display='flex' flexDirection='column'>
                    {row.shareSecure.workspaces.map((item, index) => (
                      <IconWithLabel key={index} name={item} type='workspace' onClick={handleIconClick} />
                    ))}
                  </Box>
                </TableCell>
                <TableCell align='center' sx={{ backgroundColor: "#D0E0E3" }}>
                  {row.shareSecure.documents.map((item, index) => (
                    <IconWithLabel key={index} name={item} type='document' onClick={handleIconClick} />
                  ))}
                </TableCell>
                <TableCell align='center' sx={{ backgroundColor: "#C9DAF8" }}>
                  {row.answers.reports.map((item, index) => (
                    <IconWithLabel key={index} name={item} type='report' onClick={handleIconClick} />
                  ))}
                </TableCell>
                <TableCell align='center' sx={{ backgroundColor: "#C9DAF8" }}>
                  {row.answers.insights.map((item, index) => (
                    <IconWithLabel key={index} name={item} type='insight' onClick={handleIconClick} />
                  ))}
                </TableCell>
                <TableCell align='center' sx={{ backgroundColor: "#CFE2F3" }}>
                  {row.dataPeak.map((item, index) => (
                    <IconWithLabel key={index} name={item} type='dataquality' onClick={handleIconClick} />
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={modalOpen} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {modalContent}
          </Typography>
          <Typography id='modal-modal-description' sx={{ mt: 2 }}>
            This is a placeholder for the content related to the selected action. In a real application, this would be populated with relevant
            information or functionality based on the user's selection.
          </Typography>
          <Button onClick={handleClose} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default UDM;
