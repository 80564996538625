import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  IconButton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CloseIcon from '@mui/icons-material/Close';

const MappingReview = ({ 
  fileName, 
  sourceHeaders, 
  mappings, 
  previewData, 
  onSaveMapping, 
  onApproveMapping, 
  onUpdateMapping 
}) => {
  const [editDialog, setEditDialog] = useState(null);
  const [previewColumns, setPreviewColumns] = useState([]);
  const [previewRows, setPreviewRows] = useState([]);

  useEffect(() => {
    if (previewData && mappings) {
      // Transform preview data into DataGrid format
      const columns = Object.keys(mappings).map(targetField => ({
        field: targetField,
        headerName: targetField,
        width: 200,
        renderHeader: (params) => (
          <Box>
            <Typography variant="subtitle2">{params.colDef.headerName}</Typography>
            <Typography variant="caption" color="textSecondary">
              {mappings[targetField]?.sourceHeader || 'Not Mapped'}
            </Typography>
          </Box>
        )
      }));

      setPreviewColumns(columns);
      setPreviewRows(previewData.map((row, index) => ({
        id: index,
        ...row
      })));
    }
  }, [previewData, mappings]);

  const handleEditMapping = (targetField) => {
    setEditDialog({
      targetField,
      currentMapping: mappings[targetField]?.sourceHeader || '',
      sourceHeader: mappings[targetField]?.sourceHeader || ''
    });
  };

  const handleUpdateMapping = () => {
    if (editDialog) {
      onUpdateMapping(editDialog.targetField, editDialog.sourceHeader);
      setEditDialog(null);
    }
  };

  const getConfidenceColor = (confidence) => {
    if (confidence >= 0.9) return 'success';
    if (confidence >= 0.7) return 'warning';
    return 'error';
  };

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Mapping Review: {fileName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Review and confirm the field mappings between your source file and our data model
        </Typography>
      </Box>

      {/* Mapping Table */}
      <Paper sx={{ mb: 4 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Target Field</TableCell>
                <TableCell>Source Header</TableCell>
                <TableCell>Confidence</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(mappings).map(([targetField, mapping]) => (
                <TableRow key={targetField}>
                  <TableCell>
                    <Typography variant="body2" fontWeight="medium">
                      {targetField}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {mapping.sourceHeader || (
                      <Typography variant="body2" color="error">
                        Not Mapped
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {mapping.confidence && (
                      <Chip
                        size="small"
                        icon={<AutoFixHighIcon />}
                        label={`${Math.round(mapping.confidence * 100)}%`}
                        color={getConfidenceColor(mapping.confidence)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleEditMapping(targetField)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Data Preview */}
      <Typography variant="h6" sx={{ mb: 2 }}>
        Data Preview
      </Typography>
      <Box sx={{ height: 400, mb: 4 }}>
        <DataGrid
          autosizeOnMount
          rows={previewRows}
          columns={previewColumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>

      {/* Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button
          variant="outlined"
          startIcon={<SaveIcon />}
          onClick={() => onSaveMapping(mappings)}
        >
          Save Mapping Template
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<CheckCircleOutlineIcon />}
          onClick={() => onApproveMapping(mappings)}
        >
          Approve and Process
        </Button>
      </Box>

      {/* Edit Mapping Dialog */}
      <Dialog 
        open={!!editDialog} 
        onClose={() => setEditDialog(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Edit Field Mapping
          <IconButton
            aria-label="close"
            onClick={() => setEditDialog(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Target Field
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {editDialog?.targetField}
            </Typography>
            <Autocomplete
              value={editDialog?.sourceHeader || ''}
              onChange={(event, newValue) => {
                setEditDialog(prev => ({
                  ...prev,
                  sourceHeader: newValue
                }));
              }}
              options={sourceHeaders || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Source Header"
                  fullWidth
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialog(null)}>
            Cancel
          </Button>
          <Button 
            variant="contained" 
            onClick={handleUpdateMapping}
          >
            Update Mapping
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MappingReview;