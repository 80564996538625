import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import ProductDetailsDrawer from "../../components/ProductDetailsDrawer";
import ActiveProductCard from "../../components/ActiveProductCard";
import AvailableProductCard from "../../components/AvailableProductCard";
import { useLoaderData } from "react-router-dom";
import { authedApiFetch } from "../../utils/Api";


export const dashboardLoader = async ({ params }) => {
  // Get tenant ID from URI
  const { tenantId } = params;

  const dashboardData = await authedApiFetch({
    endpoint: `/tenants/${tenantId}/dashboard`,
    method: 'GET'
  });

  return {
    activeProducts: dashboardData.active_products,
    availableProducts: dashboardData.available_products,
    availableManagedServices: dashboardData.available_managed_services,
    availableIntegrations: dashboardData.available_integrations,
    availableAddOns: dashboardData.available_add_ons
  };
};

const Launchpad = () => {
  const {
    activeProducts,
    availableProducts,
    availableManagedServices,
    availableIntegrations,
    availableAddOns
  } = useLoaderData();

  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleOpenDrawer = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseDrawer = () => {
    setSelectedProduct(null);
  };

  return (
    <Box sx={{pl: 3, pr: 3}}>
      {/* <Typography variant="h3" sx={{ backgroundColor: "#e5f6ec", p: 4}} gutterBottom>AltviaONE Dashboard</Typography> */}
      <Typography variant="h4" gutterBottom>
        Active Products & Services
      </Typography>
      <Grid container spacing={3}>
        {activeProducts.map((product) => (
          <Grid
            key={product.id}
            size={{
              xs: 12,
              sm: 6,
              md: 4
            }}>
            <ActiveProductCard
              product={product}
              handleOpenDrawer={handleOpenDrawer}
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        Available Products
      </Typography>
      <Grid container spacing={3}>
        {availableProducts.map((product) => (
          <Grid
            key={product.id}
            size={{
              xs: 12,
              sm: 6,
              md: 4
            }}>
            <AvailableProductCard
              product={product}
              handleOpenDrawer={handleOpenDrawer}
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        Available Services
      </Typography>
      <Grid container spacing={3}>
        {availableManagedServices.map((product) => (
          <Grid
            key={product.id}
            size={{
              xs: 12,
              sm: 6,
              md: 4
            }}>
            <AvailableProductCard
              product={product}
              handleOpenDrawer={handleOpenDrawer}
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        Available Integrations
      </Typography>
      <Grid container spacing={3}>
        {availableIntegrations.map((product) => (
          <Grid
            key={product.id}
            size={{
              xs: 12,
              sm: 6,
              md: 4
            }}>
            <AvailableProductCard
              product={product}
              handleOpenDrawer={handleOpenDrawer}
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        Available Add-ons
      </Typography>
      <Grid container spacing={3} sx={{pb: 3}}>
        {availableAddOns.map((product) => (
          <Grid
            key={product.id}
            size={{
              xs: 12,
              sm: 6,
              md: 4
            }}>
            <AvailableProductCard
              product={product}
              handleOpenDrawer={handleOpenDrawer}
            />
          </Grid>
        ))}
      </Grid>
      {selectedProduct && (
        <ProductDetailsDrawer
          product={selectedProduct}
          open={Boolean(selectedProduct)}
          onClose={handleCloseDrawer}
        />
      )}
    </Box>
  );
};

export default Launchpad;
