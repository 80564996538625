import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";

const TenantSwitcher = ({ tenants }) => {
  // set current tenant ID based on URI
  const { tenantId } = useParams();

  // Track current tenant in state in order to control value of autocomplete
  const [currentTenant, setCurrentTenant] = useState(null);
  useEffect(() => {
    if (tenantId != currentTenant?.id) {
      const newTenant = tenants.find((tenant) => tenant.id == tenantId);
      setCurrentTenant(newTenant);
    }
  }, [tenantId, tenants]);

  // navigate to tenant route on selection
  const navigate = useNavigate();
  const handleTenantChange = (_event, value) => {
    if (value?.id) {
      setCurrentTenant(value); // optimistically set
      navigate(`/tenants/${value.id}/dashboard`);
    }
  };

  return (
    <Box sx={{ minWidth: 250 }}>
      <Autocomplete
        options={tenants}
        getOptionLabel={(option) => option.name}
        value={currentTenant}
        onChange={handleTenantChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            sx={{
              backgroundColor: "rgb(55, 71, 79)",
              borderRadius: "4px",
              "& .MuiOutlinedInput-root": {
                color: "#fff",
                "& fieldset": {
                  borderColor: "rgb(55, 71, 79)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.5)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#fff",
                },
              },
            }}
            placeholder="Switch Tenant"
          />
        )}
      />
    </Box>
  );
};

export default TenantSwitcher;
