import React, { useEffect, useRef } from 'react';

const IdeasWidget = () => {
  const widgetRef = useRef(null);

  useEffect(() => {
    let widget;

    const config = {
      key: '9b28a04b-6194-4f9a-aee5-b59c17167f77', // Your widget key here
      container: widgetRef.current,
      callbacks: {
        onReady: (frillWidget) => {
          widget = frillWidget;
        },
      },
    };

    window.Frill_Config = window.Frill_Config || [];
    window.Frill_Config.push(config);

    if ('Frill' in window) {
      window.Frill.widget(config);
    }

    return () => {
      if (widget && widget.destroy) {
        widget.destroy();
      }
      if (window.Frill_Config) {
        window.Frill_Config = window.Frill_Config.filter((c) => c !== config);
      }
    };
  }, []);

  return <div ref={widgetRef} style={{ height: '100vh', width: '100%' }} />;
};

export default IdeasWidget;