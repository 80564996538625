import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Button } from '@mui/material';
import ActivityAll from './ActivityAll';
import ActivityAIM from './ActivityAIM';
import ActivitySS from './ActivitySS';
import ActivityAnswers from './ActivityAnswers';

const ActivityPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ p: 3, textAlign: 'center', backgroundColor: '#E6F7EC' }}>
        <Typography variant="h5" gutterBottom>
          Usage Analytics Powered by Altvia Answers
        </Typography>
        <Typography variant="body1" gutterBottom>
          Gain deeper insights and see detailed analytics with your customer data in Altvia Answers.
        </Typography>
        <Button 
          variant="contained" 
          sx={{ backgroundColor: '#6BD097', color: '#000000' }} 
          href="https://answers.altvia.com" 
          target="_blank"
        >
          See Answers with Your Data
        </Button>
      </Box>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab label="All" />
        <Tab label="AIM" />
        <Tab label="ShareSecure" />
        <Tab label="Answers" />
      </Tabs>
      <Box sx={{ p: 3 }}>
        {value === 0 && <ActivityAll />}
        {value === 1 && <ActivityAIM />}
        {value === 2 && <ActivitySS />}
        {value === 3 && <ActivityAnswers />}
      </Box>
    </Box>
  );
};

export default ActivityPage;
