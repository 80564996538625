import React, { useEffect, useState } from "react";
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import DataGridDrawer from "./DataGridDrawer";
import { useNavigation, useSearchParams } from "react-router-dom";

const DataGrid = ({ headers, rows, dataTable, rowCount, parameters }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  // update selectedRow state when records change after revalidation
  useEffect(() => {
    selectedRow && selectedRow.id && setSelectedRow(rows.find((row)=> row.id == selectedRow.id))
  },[rows])

  // Add 'id' column based on index value if none exists. It's not displayed.
  // This is necessary because the MUI data grid requires an id column.
  if (headers.find((header) => header.field === 'id') == null) {
    rows = rows.map((row, index) => {
      return {...row, id: index}
    });
  }
  
  // make modifications to headers/columns required by MuiDataGrid
  headers = headers.map((header) => {
    // add minWidth dynamically based on type
    const modifiedHeader = {
      ...header,
      minWidth: header.type === 'string' ? 150 : 50,
      type: header.type === 'double' ? 'number' : header.type
    };

    // set value getter function for certain data types    
    if (header.type == 'date' || header.type == 'dateTime') {
      modifiedHeader['valueGetter'] = (value) => value && new Date(value);
    }

    return modifiedHeader;
  })
  
  let visibleHeaders = headers;

  // for pdf display tables, we want to hide some metadata about the files
  if (dataTable.display_document) {
    const hideColumnsWith = ['_page', '_confidence', '_vertices', 'source_path'];
    visibleHeaders = headers.filter((col) => {
      return hideColumnsWith.every(hiddenCol => !col.field.includes(hiddenCol));
    })
  }

  // determine loading state by checking react router hook
  const navigation = useNavigation();
  const loading = navigation.state !== "idle";

  // react router hook: https://reactrouter.com/en/main/hooks/use-search-params
  let [searchParams, setSearchParams] = useSearchParams();

  // construct pagination model from parameters the BE reports it used
  const paginationModel = {
    page: parameters.page,
    pageSize: parameters.per
  }

  // construct sort model from parameters the BE reports it used
  const sortModel = [{ field: parameters.sort_column, sort: parameters.sort_order }]

  // see: https://mui.com/x/react-data-grid/pagination/#server-side-pagination
  const handlePaginationChange = (newPaginationModel) => {
    handleSearchParamChange({
      page: newPaginationModel.page,
      per: newPaginationModel.pageSize
    })
  }

  // see: https://mui.com/x/react-data-grid/sorting/#server-side-sorting
  const handleSortChange = (newSortModel) => {
    // handler called on page load with empty array; unsure why
    if (newSortModel.length == 0) { return null };
  
    handleSearchParamChange({
      sort_column: newSortModel[0].field,
      sort_order: newSortModel[0].sort
    })
  };

  const handleSearchParamChange = (changedParams) => {
    const currentSearchParams = Object.fromEntries(searchParams);
    let newSearchParams = new URLSearchParams({...currentSearchParams, ...changedParams});

    // React Router, acts like useNavigate but only modifies search params
    setSearchParams(newSearchParams, { replace: true });
  }

  const handleRowClick = ({ row }) => {
    setSelectedRow(row);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedRow(null);
  };

  return (
    <>
      <MuiDataGrid
        rows={rows}
        columns={visibleHeaders}
        rowCount={rowCount.count}
        loading={loading}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationChange}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortChange}
        onRowClick={handleRowClick}
        />
      <DataGridDrawer
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        selectedRow={selectedRow}
        columns={headers}
        dataTable={dataTable}
      />
    </>
  )
}

export default DataGrid;