import React, { useState } from "react";
import { Box, Typography, Card, CardContent, CardActionArea } from "@mui/material";
import Grid from '@mui/material/Grid2';
import NewsDetailsModal from "./NewsDetailsModal";
import { useOutletContext } from "react-router-dom";

const NewsList = () => {
  const { news } = useOutletContext();
  const [selectedNewsPost, setSelectedNewsPost] = useState(null);

  const handleOpenModal = (newsPost) => {
    setSelectedNewsPost(newsPost);
  };

  const handleCloseModal = () => {
    setSelectedNewsPost(null);
  };

  return (
    <Box sx={{ pl: 3, pr: 3 }}>
      <Typography variant='h4'>Product News</Typography>
      <Grid container spacing={3}>
        {news.map((post) => (
          <Grid
            size={{
              xs: 12,
              sm: 6,
              md: 4
            }}>
            <CardActionArea component='a' href='#' onClick={() => handleOpenModal(post)}>
              <Card sx={{ display: "flex", m: 1 }}>
                <CardContent sx={{ flex: 1 }}>
                  <Typography component='h2' variant='h5'>
                    {post.title}
                  </Typography>
                  <Typography variant='subtitle1' color='text.secondary'>
                    {new Date(post.published_at).toLocaleString()}
                  </Typography>
                  <Typography variant='subtitle1' paragraph dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                  <Typography variant='subtitle1' color='primary'>
                    Continue reading...
                  </Typography>
                </CardContent>
              </Card>
            </CardActionArea>
          </Grid>
        ))}
      </Grid>
      {selectedNewsPost && <NewsDetailsModal open={!!selectedNewsPost} onClose={handleCloseModal} newsPost={selectedNewsPost} />}
    </Box>
  );
};

export default NewsList;
