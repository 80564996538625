import { useEffect, useState } from 'react';
import * as PDFJS from "pdfjs-dist/build/pdf";
import "pdfjs-dist/build/pdf.worker.mjs";

window.PDFJS = PDFJS;

// This component utilizes the PDF.js library (https://github.com/mozilla/pdf.js).
// This implementation of PDF.js does not seem work well with React's <StrictMode>
// that is used in development environments. You may see an error "Cannot use the
// same canvas during multiple render() operations". This should not happen in prod
// and can be ignored in dev, or StrictMode can be commented out in index.jsx.

const PDFViewer = ({ pdfFile, vertices }) =>{
  const [fullPdfRendered, setFullPdfRendered] = useState(false);
  const [canvas, setCanvas] = useState(null);

  useEffect(() => {
    if (!pdfFile) {
      return;
    }

    async function renderPdf() {
      const loadingTask = await PDFJS.getDocument(pdfFile);

      loadingTask.promise.then((doc) => {
        const loadPage = (pageNum) => {
          return doc.getPage(pageNum).then((page) => {
            // increasing the scale does not actually make the image bigger
            // because the width of the drawer is maxed out, but it helps make
            // the image more clear and the pdf more readable
            const viewport = page.getViewport({ scale: 2.0 });
            let newCanvas = document.getElementById('pdf-viewer-canvas');
            newCanvas.height = viewport.height;
            newCanvas.width = viewport.width;
            
            const context = newCanvas.getContext('2d');
            const renderContext = {
              canvasContext: context,
              viewport: viewport
            };
            setCanvas(newCanvas);

            return page.render(renderContext).promise;
          });
        };

        // load the first page and store promise
        let lastPromise = loadPage(1);

        // load subsequent pages, chaining promises
        for (let i = 2; i <= doc.numPages; i++) {
          lastPromise = lastPromise.then(loadPage(i));
        }

        return lastPromise;
      });

      // mark full pdf as rendered
      setFullPdfRendered(true);
    }

    renderPdf();
  }, [pdfFile]);

  // used to render highlighted boxes overtop of vertices on the page
  // to show the user exactly where a piece of data is
  useEffect(() => {
    // remove all highlighted vertices if no longer defined or pdf not rendered
    if (!fullPdfRendered || !canvas || !vertices || vertices === '0' || vertices?.length === 0) {
      document.querySelectorAll('.highlighted-vertices').forEach(el => el.remove());
      return;
    }

    // the "vertices" object is four x and y values representing a rectangle, like:
    // [
    //   { "x": "0.34812286", "y": "0.12571429" },
    //   { "x": "0.43401593", "y": "0.12571429" },
    //   { "x": "0.43401593" ,"y": "0.13934065" },
    //   { "x": "0.34812286", "y": "0.13934065" }
    // ]

    // get all x values and all y values
    const xValues = JSON.parse(vertices).map(c => parseFloat(c['x']));
    const yValues = JSON.parse(vertices).map(c => parseFloat(c['y']));

    // get the raw coordinates, in relation to the top left of the page
    const coords = [
      Math.min(...xValues), // left most point (least x value from the left)
      Math.min(...yValues), // top most point (least y value from the top)
      Math.max(...xValues), // right most point (most x value from the left)
      Math.max(...yValues) // top most point (most y value from the top)
    ];

    // get the location on the page of the canvas
    const canvasLocation = canvas.getBoundingClientRect();

    // calculate the exact location of the highlight rectangle on the page.
    // first multiply the raw coordinates by the canvas size, then add the
    // offset of the canvas from the full top left of the page
    const highlightRectangle = [
      (coords[0] * canvasLocation.width) + canvasLocation.x,
      (coords[1] * canvasLocation.height) + canvasLocation.y,
      (coords[2] * canvasLocation.width) + canvasLocation.x,
      (coords[3] * canvasLocation.height) + canvasLocation.y
    ];

    // get the true x and y locations and height and width of the rectangle
    const x = Math.min(highlightRectangle[0], highlightRectangle[2]);
    const y = Math.min(highlightRectangle[1], highlightRectangle[3])
    const width = Math.abs(highlightRectangle[0] - highlightRectangle[2]);
    const height = Math.abs(highlightRectangle[1] - highlightRectangle[3]);

    // create the div with these measurements
    var overlayDiv = document.createElement('div');
    overlayDiv.setAttribute(
      'style', 'background-color: rgba(255,255,0,0.5); position: absolute; ' +
      'left: ' + x + 'px; top: ' + y + 'px; width: ' + width + 'px; height: ' + height + 'px;' +
      'z-index: 9999;'
    );
    overlayDiv.setAttribute('class', 'highlighted-vertices');

    // add the overlay div to the page
    document.body.appendChild(overlayDiv);
  }, [vertices, fullPdfRendered, canvas])
}

export default PDFViewer;