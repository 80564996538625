import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Box, Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { BarChart, LineChart } from '@mui/x-charts';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

const ActivityAll = () => {
  const [data, setData] = useState({});
  const [timeSinceLastLogin, setTimeSinceLastLogin] = useState({});
  const [sessionTrends, setSessionTrends] = useState({});

  const calculateTimeSinceLastLogin = (lastLoginData) => {
    const now = new Date();
    const timeSinceLastLogin = {};

    for (const product in lastLoginData) {
      const lastLoginDate = new Date(lastLoginData[product]);
      const timeDiff = Math.abs(now - lastLoginDate);
      const daysSinceLastLogin = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      timeSinceLastLogin[product] = `${daysSinceLastLogin} days ago`;
    }

    setTimeSinceLastLogin(timeSinceLastLogin);
  };

  const calculateSessionTrends = useCallback((sessionData) => {
    const sessionTrends = {};

    for (const product in sessionData) {
      const sessions = Object.values(sessionData[product]);
      const recentAverage = average(sessions.slice(-3)); // last 3 sessions
      const previousAverage = average(sessions.slice(-6, -3)); // previous 3 sessions

      if (recentAverage > previousAverage) {
        sessionTrends[product] = 'up';
      } else if (recentAverage < previousAverage) {
        sessionTrends[product] = 'down';
      } else {
        sessionTrends[product] = 'flat';
      }
    }

    setSessionTrends(sessionTrends);
  }, []);

  const average = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

  const renderTrendIcon = (trend) => {
    if (trend === 'up') return <ArrowUpwardIcon style={{ color: 'green' }} />;
    if (trend === 'down') return <ArrowDownwardIcon style={{ color: 'red' }} />;
    return <TrendingFlatIcon style={{ color: 'grey' }} />;
  };

  useEffect(() => {
    fetch('/activity_all.json')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data);
        setData(data);
        if (data.lastUserLogin) {
          calculateTimeSinceLastLogin(data.lastUserLogin);
        }
        if (data.sessionDuration) {
          calculateSessionTrends(data.sessionDuration);
        }
      });
  }, [calculateSessionTrends]);

  console.log(sessionTrends)

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        All Products Activity
      </Typography>
      {data.totalUsers && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6">Total Users</Typography>
          <BarChart
            series={[{ data: Object.values(data.totalUsers), label: 'Total Users' }]}
            xAxis={[{ scaleType: 'band', data: Object.keys(data.totalUsers) }]}
            height={300}
            margin={{ top: 20, right: 30, bottom: 40, left: 50 }}
          />
        </Box>
      )}
      <Grid container spacing={2} sx={{ marginTop: 4 }}>
        {timeSinceLastLogin.AIM && (
          <Grid
            size={{
              xs: 12,
              md: 4
            }}>
            <Card>
              <CardContent>
                <Typography variant="h6">AIM Last User Login</Typography>
                <Typography variant="h5">{timeSinceLastLogin.AIM}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}

        {timeSinceLastLogin.ShareSecure && (
          <Grid
            size={{
              xs: 12,
              md: 4
            }}>
            <Card>
              <CardContent>
                <Typography variant="h6">ShareSecure Last User Login</Typography>
                <Typography variant="h5">{timeSinceLastLogin.ShareSecure}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}

        {timeSinceLastLogin.Answers && (
          <Grid
            size={{
              xs: 12,
              md: 4
            }}>
            <Card>
              <CardContent>
                <Typography variant="h6">Answers Last User Login</Typography>
                <Typography variant="h5">{timeSinceLastLogin.Answers}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: 4 }}>
        {sessionTrends.aim && (
          <Grid
            size={{
              xs: 12,
              md: 4
            }}>
            <Card>
              <CardContent>
                <Typography variant="h6">AIM Session Duration</Typography>
                <Typography variant="h5">{average(Object.values(data.sessionDuration.aim)).toFixed(2)} mins</Typography>
                {renderTrendIcon(sessionTrends.AIM)}
              </CardContent>
            </Card>
          </Grid>
        )}

        {sessionTrends.ss && (
          <Grid
            size={{
              xs: 12,
              md: 4
            }}>
            <Card>
              <CardContent>
                <Typography variant="h6">ShareSecure Session Duration</Typography>
                <Typography variant="h5">{average(Object.values(data.sessionDuration.ss)).toFixed(2)} mins</Typography>
                {renderTrendIcon(sessionTrends.ShareSecure)}
              </CardContent>
            </Card>
          </Grid>
        )}

        {sessionTrends.answers && (
          <Grid
            size={{
              xs: 12,
              md: 4
            }}>
            <Card>
              <CardContent>
                <Typography variant="h6">Answers Session Duration</Typography>
                <Typography variant="h5">{average(Object.values(data.sessionDuration.answers)).toFixed(2)} mins</Typography>
                {renderTrendIcon(sessionTrends.Answers)}
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      {data.activeUsers && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6">Active Users</Typography>
          <LineChart
            series={[
              { data: Object.values(data.activeUsers.aim || {}), label: 'AIM' },
              { data: Object.values(data.activeUsers.ss || {}), label: 'ShareSecure' },
              { data: Object.values(data.activeUsers.answers || {}), label: 'Answers' }
            ]}
            xAxis={[{ scaleType: 'time', data: Object.keys(data.activeUsers.aim || {}).map(date => new Date(date)), label: 'Date' }]}
            yAxis={[{ label: 'Number of Active Users' }]}
            height={300}
            margin={{ top: 20, right: 30, bottom: 40, left: 50 }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ActivityAll;
