import React from "react";
import { Drawer, Box, Typography, List, ListItem, ListItemText, Link, Divider } from "@mui/material";
import Grid from '@mui/material/Grid2';

const ProductDetailsDrawer = ({ product, open, onClose }) => {
  // Ensure categories is an array
  const categories = Array.isArray(product.categories) ? product.categories : product.categories ? product.categories.split(", ") : [];

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box sx={{ width: 600, p: 2 }}>
        <Typography variant='h4' gutterBottom>
          {product.name}
        </Typography>
        <Typography variant='body2' gutterBottom>
          {product.description}
        </Typography>
        <Divider sx={{ my: 1 }} />

        <Typography variant='subtitle2' gutterBottom>
          This is where Complementary Products will be displayed
        </Typography>

        <Divider sx={{ my: 1 }} />
        <List dense>
          <ListItem>
            <ListItemText
              primary='Version'
              secondary={product.version}
              primaryTypographyProps={{ component: "span" }}
              secondaryTypographyProps={{
                component: "span",
                display: "inline-block",
                fontWeight: "bold",
              }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Pricing'
              secondary={product.pricing}
              primaryTypographyProps={{ component: "span" }}
              secondaryTypographyProps={{
                component: "span",
                display: "inline-block",
                fontWeight: "bold",
              }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary='Access'
              secondary={product.access}
              primaryTypographyProps={{ component: "span" }}
              secondaryTypographyProps={{
                component: "span",
                display: "inline-block",
                fontWeight: "bold",
              }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary='Geographic Coverage'
              secondary={product.geographic_coverage}
              primaryTypographyProps={{ component: "span" }}
              secondaryTypographyProps={{
                component: "span",
                display: "inline-block",
                fontWeight: "bold",
              }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary='Categories'
              secondary={categories.join(", ")}
              primaryTypographyProps={{ component: "span" }}
              secondaryTypographyProps={{
                component: "span",
                display: "inline-block",
                fontWeight: "bold",
              }}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            />
          </ListItem>

          <ListItem>
            <Grid container>
              <Grid
                size={{
                  xs: 12,
                  sm: 3
                }}>
                <Typography component='span'>Use Cases</Typography> 
              </Grid>
              <Grid
                sx={{ textAlign: "right" }}
                size={{
                  xs: 12,
                  sm: 9
                }}>
                <Typography component='span' sx={{ fontWeight: "bold" }}>
                  {product.use_cases}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        </List>

        {product.provider && (
          <>
            <Divider sx={{ my: 1 }} />
            <Typography variant='h6' gutterBottom>
              Provider Information
            </Typography>
            <List dense>
              <ListItem>
                <ListItemText
                  primary='Name'
                  secondary={product.provider.name}
                  primaryTypographyProps={{ display: "inline" }}
                  secondaryTypographyProps={{
                    display: "inline",
                    ml: 5,
                    fontWeight: "bold",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary='Support'
                  secondary={
                    <Link href={product.provider.support} target='_blank'>
                      {product.provider.support}
                    </Link>
                  }
                  primaryTypographyProps={{ display: "inline" }}
                  secondaryTypographyProps={{
                    display: "inline",
                    ml: 5,
                    fontWeight: "bold",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary='Website'
                  secondary={
                    <Link href={product.provider.website} target='_blank'>
                      {product.provider.website}
                    </Link>
                  }
                  primaryTypographyProps={{ display: "inline" }}
                  secondaryTypographyProps={{
                    display: "inline",
                    ml: 5,
                    fontWeight: "bold",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary='Description'
                  secondary={product.provider.description}
                  primaryTypographyProps={{ display: "inline" }}
                  secondaryTypographyProps={{
                    display: "inline",
                    ml: 5,
                    fontWeight: "bold",
                  }}
                />
              </ListItem>
            </List>
          </>
        )}

        {product.product_links && (
          <>
            <Divider sx={{ my: 1 }} />
            <Typography variant='h6' gutterBottom>
              Product Links
            </Typography>
            <List dense>
              {product.product_links.map((link, index) => (
                <ListItem key={index} component='a' href={link.url} target='_blank' button>
                  <ListItemText primary={link.name} />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default ProductDetailsDrawer;
