import React from 'react';
import { Modal, Box, Typography, CardMedia } from '@mui/material';

const NewsDetailsModal = ({ open, onClose, newsPost }) => {
  if (!newsPost) return null;

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ p: 4, bgcolor: 'background.paper', margin: 'auto', maxWidth: 600 }}>
        <Typography variant="h5" gutterBottom>
          {newsPost.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {new Date(newsPost.published_at).toLocaleString()}
        </Typography>
        {newsPost.image_url && (
          <CardMedia
            component="img"
            image={newsPost.image_url}
            alt={newsPost.title}
            sx={{ mb: 2 }}
          />
        )}
        <Box dangerouslySetInnerHTML={{ __html: newsPost.content }} />
      </Box>
    </Modal>
  );
};

export default NewsDetailsModal;
