import React, { useState } from 'react';
import { getMockCases } from '../../services/mockDataService';
import { Box, Typography, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const TamCases = () => {
  const { tam } = getMockCases();
  const [cases, setCases] = useState(tam.cases);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(cases);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCases(items);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid
          size={{
            xs: 12,
            sm: 4
          }}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AssignmentTurnedInOutlinedIcon fontSize="large" />
                <Box sx={{ marginLeft: 2 }}>
                  <Typography variant="h6">Total Hours</Typography>
                  <Typography variant="h4">{tam.stats.totalHours}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4
          }}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ScheduleOutlinedIcon fontSize="large" />
                <Box sx={{ marginLeft: 2 }}>
                  <Typography variant="h6">Hours Used</Typography>
                  <Typography variant="h4">{tam.stats.totalUsed}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4
          }}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccessTimeOutlinedIcon fontSize="large" />
                <Box sx={{ marginLeft: 2 }}>
                  <Typography variant="h6">Hours Remaining</Typography>
                  <Typography variant="h4">{tam.stats.totalRemaining}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 2 }}>
        <Typography variant="h6">Outstanding Requests</Typography>
        <Button variant="contained" color="primary">Create New Case</Button>
      </Box>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable-cases">
          {(provided) => (
            <TableContainer component={Paper} {...provided.droppableProps} ref={provided.innerRef}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Estimated Hours</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cases.map((c, index) => (
                    <Draggable key={c.id} draggableId={c.id} index={index}>
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TableCell>{c.title}</TableCell>
                          <TableCell>{c.status}</TableCell>
                          <TableCell>{c.estimatedHours}</TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default TamCases;
