import { createTheme } from '@mui/material/styles';

const altviaTheme = createTheme({
  palette: {
    primary: {
      main: '#2E86AB',
      contrastText: '#FFFFFF',
      border: '#2E86AB'
    },
    secondary: {
      main: '#67A9CF',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#FFF',
      paper: '#FFFFFF',
    },
    text: {
      // primary: '#333333',
      primary: 'rgb(17, 23, 28)',
      secondary: '#555555', 
    },
  },
  typography: {
    fontFamily: '"Inter","Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '3rem',
      color: 'rgb(17, 23, 28)',
    },
    h2: {
      fontWeight: 700,
      fontSize: '2.5rem',
      color: 'rgb(17, 23, 28)',
    },
    h3: {
      fontWeight: 700,
      fontSize: '2rem',
      color: 'rgb(17, 23, 28)',
    },
    h4: {
      fontWeight: 800,
      fontSize: '1.5rem',
      color: 'rgb(17, 23, 28)',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.4rem',
      color: 'rgb(17, 23, 28)',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.25rem',
      color: 'rgb(17, 23, 28)',
    },
    body1: {
      fontSize: '1rem',
      color: 'rgb(17, 23, 28)',
    },
    body2: {
      fontSize: '0.875rem',
      color: 'rgb(17, 23, 28)',
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '10px 20px',
          textTransform: 'none',
          fontWeight: 600,
        },
        containedPrimary: {
          backgroundColor: '#90EDB9',
          color: '#000',
          '&:hover': {
            backgroundColor: '#3CBC73',
            color: '#FFFFFF',
          },
        },
        containedSecondary: {
          backgroundColor: '#67A9CF',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#5297A7',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          elevation: 0,
          borderRadius: 12,
          boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)',
          overflow: 'hidden',
          transition: 'box-shadow 0.3s ease-in-out',
          '&:hover': {
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          objectFit: 'cover',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '12px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontWeight: 'bold',
          marginBottom: '8px',
        },
        subtitle1: {
          marginBottom: '8px',
        },
        body1: {
          color: '#616161',
          fontSize: '0.875rem',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#2E86AB',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#2E86AB',
            },
            '& .MuiListItemIcon-root': {
              color: '#FFFFFF',
            },
            '& .MuiListItemText-root': {
              color: '#FFFFFF',
            },
            '& .MuiBadge-root': {
              backgroundColor: '#FFFFFF',
              color: '#000000',
            },
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: '#FFFFFF',
          color: '#000000',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(55, 71, 79)',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
        },
      },
    },
  },
});

export default altviaTheme;
