import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { BarChart } from '@mui/x-charts';

const ActivityAnswers = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/activity_answers.json')
      .then(response => response.json())
      .then(data => setData([
        { name: 'Dashboards', value: 100 },
        { name: 'Widgets', value: 200 },
        { name: 'Views', value: 300 },
        { name: 'Active Users', value: 400 },
      ]));
  }, []);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Answers Activity
      </Typography>
      <BarChart
        series={[
          { data: data, label: 'Usage' }
        ]}
        xAxis={[
          { scaleType: 'band', data: ['Dashboards', 'Widgets', 'Views', 'Active Users'] }
        ]}
        height={300}
        margin={{ top: 20, right: 30, bottom: 40, left: 50 }}
      />
    </Box>
  );
};

export default ActivityAnswers;
