import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Drawer, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { getMockCases } from '../../services/mockDataService';

const CsmCases = () => {
  // use mock case data; TODO: after resolving SF auth, get from loader function
  const { csm } = getMockCases();
  const cases = csm.cases;

  // React hooks
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [newCase, setNewCase] = useState({ subject: '', description: '', priority: '' });

  // Event handlers
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setNewCase({ subject: '', description: '', priority: '' }); // Reset new case form
  };

  const handleNewCaseChange = (e) => {
    const { name, value } = e.target;
    setNewCase((prev) => ({ ...prev, [name]: value }));
  };

  const handleNewCaseSubmit = () => {
    // Logic to submit the new case
    console.log('New case data:', newCase);
    handleDrawerClose(); // Close drawer after submitting
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            sm: 3
          }}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Cases</Typography>
              <Typography variant="h4">{cases.length}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 3
          }}>
          <Card>
            <CardContent>
              <Typography variant="h6">Open Cases</Typography>
              <Typography variant="h4">{cases.filter(c => c.Status !== 'Completed').length}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 3
          }}>
          <Card>
            <CardContent>
              <Typography variant="h6">High Priority Cases</Typography>
              <Typography variant="h4">{cases.filter(c => c.Priority === 'High').length}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 3
          }}>
          <Card>
            <CardContent>
              <Typography variant="h6">Avg. Resolution Time</Typography>
              <Typography variant="h4">2 days</Typography> {/* Placeholder for now */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 2 }}>
        <Typography variant="h6">Support Cases</Typography>
        <Button variant="contained" color="primary" onClick={handleDrawerOpen}>Add Support Case</Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Priority</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cases.length > 0 ? (
              cases.map((c) => (
                <TableRow key={c.Id}>
                  <TableCell>{c.Subject}</TableCell>
                  <TableCell>{c.Status}</TableCell>
                  <TableCell>{c.Priority}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">No cases available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Drawer for creating a new case */}
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ width: 600, padding: 2 }}>
          <Typography variant="h6">Create New Case</Typography>
          <TextField
            name="subject"
            label="Subject"
            fullWidth
            margin="normal"
            value={newCase.subject}
            onChange={handleNewCaseChange}
          />
          <TextField
            name="priority"
            label="Priority"
            fullWidth
            margin="normal"
            value={newCase.priority}
            onChange={handleNewCaseChange}
          />
          <TextField
            name="description"
            label="Description"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={newCase.description}
            onChange={handleNewCaseChange}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleNewCaseSubmit}
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
};

export default CsmCases;
