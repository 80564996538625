import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

const ActivitySS = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/activity_ss.json')
      .then(response => response.json())
      .then(data => setData([
        { name: 'Document A', value: 400 },
        { name: 'Document B', value: 300 },
        { name: 'Document C', value: 300 },
      ]));
  }, []);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        ShareSecure Activity
      </Typography>
      <PieChart
        series={[
          { data: data }
        ]}
        height={300}
        margin={{ top: 20, right: 30, bottom: 40, left: 50 }}
      />
    </Box>
  );
};

export default ActivitySS;
