import { Box, Drawer, Typography, TextField } from '@mui/material';
import { useFetcher } from 'react-router-dom';
import { authedApiFetch } from '../../utils/Api';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import PDFViewer from './PdfViewer';

export async function recordUpdateAction({ request, params }) {
  const { dataTableId, recordId } = params;

  // get form data as object
  const formData = await request.formData();
  const formDataObj = Object.fromEntries(formData);

  // replace empty string values with null in new object
  formData.entries().forEach(([key, value]) => {
    if (value === '') {
      formDataObj[key] = null
    }
  })

  return await authedApiFetch({
    endpoint: `/data_tables/${dataTableId}/records/${recordId}`,
    method: 'PATCH',
    payload: {
      record: formDataObj
    }
  });
}

export async function approvalAction({ params }) {
  const { dataTableId, recordId } = params;

  return await authedApiFetch({
    endpoint: `/data_tables/${dataTableId}/records/${recordId}/approve`,
    method: 'POST'
  });
}

export async function getPresignedUrlAction({ params }) {
  const { dataTableId, recordId, path } = params;

  return await authedApiFetch({
    endpoint: `/data_tables/${dataTableId}/records/${recordId}/presigned_url?path=${encodeURIComponent(path)}`,
    method: 'GET'
  });
}

const DataGridDrawer = ({ columns, selectedRow, handleDrawerClose, drawerOpen, dataTable}) => {
  const [rowData, setRowData] = useState({});
  const [pdfFile, setPdfFile] = useState(null);
  const [vertices, setVertices] = useState(null);

  useEffect(() => {
    setRowData(selectedRow);

    if (selectedRow && dataTable.display_document && selectedRow[dataTable.document_location_column]) {
      (async () => {
        const data = await getPresignedUrlAction({
          params: {
            recordId: selectedRow[dataTable.unique_id_column],
            dataTableId: dataTable.id,
            path: selectedRow[dataTable.document_location_column]
          }
        });
        setPdfFile(data?.url);
      })();
    }
  }, [selectedRow, dataTable]);

  // check editable once to determine default readOnly status
  const readOnly = !dataTable.editable || (dataTable.approvable && selectedRow?.Approved)

  // initialize read only columns array with either defined id column or default
  const uniqueIdColumn = dataTable.unique_id_column || 'id' 
  const readOnlyColumns = [uniqueIdColumn]

  // by default, set all columns as visible in the drawer
  let visibleColumns = columns;

  // when displaying a pdf, hide some metadata that we need for rendering the file
  if (dataTable.display_document) {
    // these are similar to those in the DataGrid component, but here
    // we will display confidence columns
    const hideColumnsWith = ['_page', '_vertices', 'source_path'];
    visibleColumns = columns.filter((col) => {
      return hideColumnsWith.every(hiddenCol => !col.field.includes(hiddenCol));
    })

    // make the confidence columns read only
    columns.forEach((col) => {
      if (col.field.includes('_confidence')) {
        readOnlyColumns.push(col.field)
      }
    })
  }

  // if table is approvable, add approval fields to read-only columns
  if (dataTable.approvable) {
    readOnlyColumns.push('Approved', dataTable.approved_at_column, dataTable.approved_by_column)
  }

  // react router component for performing data mutation without navigation
  const fetcher = useFetcher();

  // check whether action is submitting or loading, and which action is loading
  const loading = fetcher.state !== 'idle'
  const updating = loading && fetcher.formAction.endsWith("update");
  const approving = loading && fetcher.formAction.endsWith("approve");

  // get vertices when mousing over a field
  const handleMouseOver = (event) => {
    const field = event.target.name;
    const baseField = field?.substring(0, field.lastIndexOf("_") + 1);
    const vertices = selectedRow[baseField + 'vertices'];
    if (vertices) {
      setVertices(vertices);
    }
  };

  const handleMouseOut = () => {
    setVertices(null);
  };

  const handleChange = (event) => {
    const newData = {...rowData};
    newData[event.target.name] = event.target.value;
    setRowData(newData);
  };

  const handleClose = () => {
    setPdfFile(null);
    setVertices(null);
    handleDrawerClose();
  }

  return (
    <Drawer
        anchor='right'
        open={drawerOpen}
        onClose={handleClose}
        display="flex"
        flexdirection="row"
        justifycontent="flex-end"
      >
        {pdfFile &&
          <Box
            sx={{ maxWidth: 700, right: 600 }}
            role="presentation"
            position="fixed"
          >
            <canvas style={{ width: '100%' }} id='pdf-viewer-canvas'>
              <PDFViewer pdfFile={pdfFile} vertices={vertices} />
            </canvas>
          </Box>
        }
        <Box
          sx={{ width: 600, p: 2 }}
          role="presentation"
          >
          <Typography variant="h6" sx={{ mb: 2 }}>
            {readOnly ? "View Record (Read Only)" : "Edit Record"}
          </Typography>

          {/* This section throws an error when selectedRow is cleared, so it's conditionally shown */}
          {rowData && <>
            <fetcher.Form method="post" action={`${rowData[uniqueIdColumn]}/update`}>
              {visibleColumns.map((column) => (
                <TextField
                  disabled={readOnly || loading || readOnlyColumns.includes(column.field)}
                  key={column.field}
                  label={column.field}
                  name={column.field}
                  value={rowData[column.field] || ''}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  multiline
                  minRows={1}
                  maxRows={10}
                  sx={{ mb: 2 }}
                />
              ))}
              <LoadingButton loading={updating} disabled={readOnly || loading} type="submit" variant="outlined" color="primary">
                Save Changes
              </LoadingButton>
            </fetcher.Form>

            <fetcher.Form method="post" action={`${rowData[uniqueIdColumn]}/approve`}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="secondary"
                name="approved"
                value="true"
                loading={approving}
                disabled={loading || !dataTable.approvable || rowData.Approved}
                sx={{mt: 2}}
              >
                {rowData.Approved ? 'Approved' : 'Approve'}
              </LoadingButton>
            </fetcher.Form>
          </>}
      </Box>
    </Drawer>
  )
}

export default DataGridDrawer;