import React, { Suspense, useEffect } from 'react';
import { authedApiFetch } from '../../utils/Api';
import { Await, defer, useLoaderData, useOutletContext } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import DataGrid from './DataGrid';

export async function loader({ params, request }) {
  // used for extracting search params
  const url = new URL(request.url);

  const tablePromise = authedApiFetch({
    endpoint: `/data_tables/${params.dataTableId}/records?${url.searchParams}`,
    method: 'GET'
  })

  // TODO: after implementing filtering, will need to pass filters to endpoint
  const rowCountPromise = authedApiFetch({
    endpoint: `/data_tables/${params.dataTableId}/records/count`,
    method: 'GET'
  })


  const dataPromise = Promise.all([tablePromise, rowCountPromise]);

  return defer({ data: dataPromise });
}

const DataTableRecords = () => {
  const { data } = useLoaderData();

  // set value in parent through callback in outlet context
  const { setTab, dataTable } = useOutletContext();
  useEffect(() => {
    setTab('records');
  }, []);

  return (
    <>
      <Suspense
        fallback={<Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ flexGrow: 1 }}
        >
          <p>Retrieving data...</p>
          <CircularProgress/>
        </Box>}
      >
        <Await
          resolve={data}
          errorElement={
            <p>Error loading table</p>
          }
        >
          {([table, rowCount]) => (
            <DataGrid
              headers={table.headers}
              rows={table.rows}
              dataTable={dataTable}
              parameters={table.parameters}
              rowCount={rowCount}
            />
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default DataTableRecords;