import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Button, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const FileViewer = ({ fileName, onSave, onSaveAndApprove, onClose }) => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const mockData = [
      { id: 1, name: "John Doe", email: "john@example.com", company: "Acme Inc." },
      { id: 2, name: "Jane Smith", email: "jane@example.com", company: "XYZ Corp" },
      { id: 3, name: "Bob Johnson", email: "bob@example.com", company: "ABC Ltd" },
    ];

    setRows(mockData);
    setColumns([
      { field: "id", headerName: "ID", width: 70, editable: true },
      { field: "name", headerName: "Name", width: 200, editable: true },
      { field: "email", headerName: "Email", width: 200, editable: true },
      { field: "company", headerName: "Company", width: 200, editable: true },
    ]);
  }, [fileName]);

  const handleSave = () => {
    onSave(rows);
  };

  const handleSaveAndApprove = () => {
    onSaveAndApprove(rows);
  };

  return (
    <Box sx={{ pl: 3, pr: 3 }}>
        <Typography variant='h5' sx={{ mb: 2 }}>
          File Viewer: {fileName}
        </Typography>
      <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button variant='outlined' startIcon={<SaveIcon />} onClick={handleSave} sx={{ mr: 2 }}>
          Save
        </Button>
        <Button variant='outlined' color='success' startIcon={<CheckCircleOutlineIcon />} onClick={handleSaveAndApprove} sx={{ mr: 2 }}>
          Save and Approve
        </Button>
      </Box>
      <Box sx={{ height: 300, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </Box>
    </Box>
  );
};

export default FileViewer;
