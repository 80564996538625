// mockDataService.js
export const getMockCases = () => {
  return {
    tam: {
      stats: {
        totalHours: 100,
        totalUsed: 60,
        totalRemaining: 40,
      },
      cases: [
        { id: '1', title: 'Add New Custom Objects', status: 'In Progress', estimatedHours: 10 },
        { id: '2', title: 'Integration with New Accounting Systems', status: 'Pending', estimatedHours: 20 },
        { id: '3', title: 'Upgrade Answers to Quicksight', status: 'Completed', estimatedHours: 30 },
      ],
    },
    csm: {
      stats: {
        totalCases: 5,
        openCases: 4,
        highPriorityCases: 2,
        averageResolutionTime: '2 hrs',
      },
      cases: [
        { Id: '1', Subject: 'Email Campaign Issue', Status: 'In Progress', Priority: 'High' },
        { Id: '2', Subject: 'Data Export Problem', Status: 'Pending', Priority: 'Medium' },        
        { Id: '4', Subject: 'Billing Discrepancy', Status: 'In Progress', Priority: 'Medium' },
        { Id: '5', Subject: 'Login Issues', Status: 'Pending', Priority: 'High' },
        { Id: '3', Subject: 'User Permissions Error', Status: 'Resolved', Priority: 'Low' },
      ],
    },
  };
};
