import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import alpsBackground from "../assets/images/alps-background.jpg";
import altviaLogo from "../assets/images/altvia-logo-dark.svg";
import { apiFetch } from "../utils/Api";

const Login = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async () => {
    try {
      const response = await apiFetch({
        endpoint: '/auth/login',
        method: 'POST',
        payload: { email }
      });

      if (response && response.login_url) {
        sessionStorage.setItem("email", email); // for later use by auth callback
        window.location.replace(response.login_url);
      } else {
        console.error("Login URL not provided.");
        setErrorMessage("User not found. Please contact your administrator or Altvia Support.");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setErrorMessage("An error occurred during login. Please try again or contact support.");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <Grid container style={{ minHeight: "100vh", position: "relative" }}>
      <Grid
        style={{
          backgroundImage: `url(${alpsBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center top",
          position: "relative",
        }}
        size={{
          xs: 12,
          md: 6
        }}>
        <Box
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.85)",
          }}
        />
      </Grid>
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        direction='column'
        size={{
          xs: 12,
          md: 6
        }}>
        <Box width={300} display='flex' flexDirection='column'>
          <img src={altviaLogo} alt='Altvia Logo' style={{ marginBottom: 20 }} />
          <TextField
            label='Email'
            variant='outlined'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin='normal'
            onKeyPress={handleKeyPress}
            autoFocus
          />
          <Button variant='contained' color='primary' onClick={handleLogin} style={{ marginTop: 20 }}>
            Login
          </Button>
          {errorMessage && (
            <Typography variant='body2' color='error' style={{ marginTop: 10 }}>
              {errorMessage}
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
