import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';

const ActivityAIM = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/activity_aim.json')
      .then(response => response.json())
      .then(data => setData([data]));
  }, []);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        AIM Activity
      </Typography>
      <LineChart
        series={[
          { data: data.map(item => item.numberOfAccounts), label: 'Accounts' },
          { data: data.map(item => item.numberOfContacts), label: 'Contacts' },
          { data: data.map(item => item.numberOfDeals), label: 'Deals' }
        ]}
        xAxis={[
          { scaleType: 'band', data: ['Accounts', 'Contacts', 'Deals'] }
        ]}
        height={300}
        margin={{ top: 20, right: 30, bottom: 40, left: 50 }}
      />
    </Box>
  );
};

export default ActivityAIM;
