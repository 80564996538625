
const apiBaseUrl = process.env.REACT_APP_API_URL

export const apiFetch = async ({endpoint, method, payload, params}) => {
  try {
    let url = `${apiBaseUrl}${endpoint}`;
    
    // Use browser-standard URLSearchParams API to add query params to URL
    if (params) {
      const searchParams = new URLSearchParams(params);

      // add ? if it isn't there yet (endpoint might already have params)
      if (!url.includes('?')) {
        url += '?';
      }

      url += `${searchParams}`;
    }

    const resp = await fetch(url, {
      method: method,
      headers: {'Content-type': 'application/json'},
      body: JSON.stringify(payload)
    });

    if (resp.ok) {
      const respBody = await resp.json();
      return respBody;
    } else {
      throw new Error(`Call to ${url} returned unsuccesful response: ${resp.status}`);
    }
  } catch (error) {
    throw new Error(`Call failed with error: ${error}`);
  }
};

export const needToLogIn = () => {
  // Get Cognito ID token and user data from session storage
  const token = sessionStorage.getItem("idToken");
  const user = sessionStorage.getItem("user");

  // If token and/or user is missing, return false
  // TODO: improve this to do some kind of check for token expiration
  return !(token && user)
}


export const authedApiFetch = async ({endpoint, method, payload = null, returnResponse = false}) => {
  try {
    const token = sessionStorage.getItem("idToken");
    const url = `${apiBaseUrl}${endpoint}`
    const resp = await fetch(url, {
      method: method,
      headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
      },
      body: payload ? JSON.stringify(payload) : null
    })

    // TODO: refresh token instead of siging out on 401
    if (resp.status == 401) {
      // this will end up sending the user to login
      sessionStorage.clear();
    }

    // in some cases, like forms that need validation errors, return response
    if (returnResponse) {
      return resp;
    } else if (resp.ok) {
      const respBody = await resp.json();
      return respBody;
    } else {
      throw new Error(`Call to ${url} returned unsuccessful response: ${resp.status}`);
    }
  } catch (error) {
    throw new Error(`Call failed with error: ${error}`);
  }
};