import appflowLogo from './appflow-logo.svg';
import dropboxLogo from './dropbox-logo.svg';
import googleDriveLogo from './google-drive-logo.svg';
import awsRdsLogo from './aws-rds-logo.svg';
import postgresLogo from './postgres-logo.svg';
import restApiLogo from './rest-api-logo.svg';
import gsheetsLogo from './gsheets-logo.svg';
import fivetranLogo from './fivetran.svg';
import databricksLogo from './databricks-logo.svg';

const logoMapping = {
  appflow: appflowLogo,
  dropbox: dropboxLogo,
  googleDrive: googleDriveLogo,
  awsRds: awsRdsLogo,
  postgres: postgresLogo,
  restApi: restApiLogo,
  gsheets: gsheetsLogo,
  fivetran: fivetranLogo,
  databricks: databricksLogo
};

export default logoMapping;
